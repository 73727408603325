*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-size: 10px;
}

html:focus-within {
  scroll-behavior: smooth;
}

body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  font-family: Nunito, Helvetica Neue, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img,
picture {
  max-width: 100%;
  display: block;
}

input,
button,
textarea,
select {
  font: inherit;
}

button {
  color: inherit;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
}

body.no-scroll {
  overflow: hidden !important;
}

/* Tiny */
.tox-tinymce {
  border-radius: 12px !important;
  border: 1px solid #e4e4e4 !important;
}
.tox .tox-edit-area__iframe {
  background-color: transparent !important;
}
.tox .tox-toolbar,
.tox .tox-toolbar__overflow,
.tox .tox-toolbar__primary {
  background-color: #e4e4e44d !important;
}
